<script setup lang="ts">
const localePath = useLocalePath();
const patientStore = usePatientStore();
const router = useRouter();
</script>

<template>
  <el-menu
    class="patient-menu"
    mode="horizontal"
    :default-active="router.currentRoute.value.fullPath"
    :ellipsis="false"
    router
  >
    <el-menu-item
      :index="localePath(`/patients/${patientStore.activePatientId}`)"
      >Données du patient</el-menu-item
    >
    <el-menu-item
      :index="
        localePath(`/patients/${patientStore.activePatientId}/objectives`)
      "
      >Objectifs</el-menu-item
    >
    <el-sub-menu index="consultations">
      <template #title>Consultation</template>
      <el-menu-item
        :index="
          localePath(
            `/patients/${patientStore.activePatientId}/consultations/new`,
          )
        "
        >Nouvelle consultation</el-menu-item
      >
      <el-menu-item
        :index="
          localePath(
            `/patients/${patientStore.activePatientId}/consultations/last`,
          )
        "
        >Dernière consultation</el-menu-item
      >
      <el-menu-item
        :index="
          localePath(`/patients/${patientStore.activePatientId}/consultations`)
        "
        >Toutes les consultations</el-menu-item
      >
    </el-sub-menu>
    <el-sub-menu index="food-survey">
      <template #title>Enquête Diététique</template>
      <el-menu-item
        :index="
          localePath(
            `/patients/${patientStore.activePatientId}/food-survey/new`,
          )
        "
        >Nouvelle enquête</el-menu-item
      >
      <el-menu-item
        :index="
          localePath(
            `/patients/${patientStore.activePatientId}/food-survey/last`,
          )
        "
        >Dernière enquête</el-menu-item
      >
      <el-menu-item
        :index="
          localePath(`/patients/${patientStore.activePatientId}/food-survey`)
        "
        >Toutes les enquêtes</el-menu-item
      >
    </el-sub-menu>
    <el-sub-menu index="analysis">
      <template #title>Analyses</template>
      <el-menu-item
        :index="
          localePath(`/patients/${patientStore.activePatientId}/analysis/new`)
        "
        >Nouvelle analyse</el-menu-item
      >
      <el-menu-item
        :index="
          localePath(`/patients/${patientStore.activePatientId}/analysis/last`)
        "
        >Dernière analyse</el-menu-item
      >
      <el-menu-item
        :index="
          localePath(`/patients/${patientStore.activePatientId}/analysis`)
        "
        >Toutes les analyses</el-menu-item
      >
    </el-sub-menu>
  </el-menu>
</template>
