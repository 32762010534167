<script setup lang="ts">
const patientStore = usePatientStore();
const modalStore = useModalStore();

const route = useRoute();
const localePath = useLocalePath();

const props = defineProps({
  isLoading: Boolean,
  loaderText: String,
});

function handleBackCTAClick() {
  patientStore.clearActivePatient();
  navigateTo(localePath('patients'));
}
async function handleEditInfos() {
  await patientStore.fetchDetailedPatient(patientStore.activePatient.id);
  await modalStore.openModal(modalStore.AvailableModal.PatientUpsert);
}
</script>
<template>
  <NuxtLayout>
    <template #header-title v-if="!patientStore.activePatient">
      Mes Patients
    </template>

    <template #header-content v-else>
      <div
        class="patient-card flex gap-2"
        v-if="!props.isLoading && patientStore.activePatient"
      >
        <div class="flex flex-col">
          <div class="flex flex-col">
            <div class="flex items-center gap-2">
              <BaseIcon
                size="18"
                :icon="`tabler:gender-${patientStore.activePatient.gender}`"
              />
              <h3 class="p-0 m-0">
                {{ patientStore.activePatient.firstname }}
                {{ patientStore.activePatient.lastname }}
              </h3>

              <strong>
                {{ getAgeFromDate(patientStore.activePatient.date_of_birth) }}
                ans
              </strong>
            </div>
            <div class="flex items-center gap-2">
              <BaseIcon size="15" icon="material-symbols:mail" />
              <a
                :href="`mailto:${patientStore.activePatient.email}`"
                class="text-sm hover:underline"
                >{{ patientStore.activePatient.email }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #header-cta>
      <slot name="header-cta" v-if="!props.isLoading"></slot>
      <BaseButton
        v-if="route.path !== localePath('patients')"
        @click="handleBackCTAClick"
        icon="material-symbols:arrow-back-ios-rounded"
        text="Retour aux patients"
      >
      </BaseButton>
      <BaseButton
        v-if="!props.isLoading && patientStore.activePatient"
        type="secondary"
        @click="handleEditInfos"
        icon="material-symbols:person-edit-rounded"
        text="Éditer le patient"
      ></BaseButton>
    </template>

    <PatientsHeaderMenu v-if="!props.isLoading && patientStore.activePatient" />

    <div class="w-full h-[calc(100%-60px)] bg-gray-50 overflow-scroll">
      <BaseLoader
        class="absolute top-1/2 left-1/2"
        v-if="props.isLoading"
        :text="props.loaderText"
      />
      <slot v-else />
    </div>
  </NuxtLayout>
</template>
